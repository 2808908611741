'use client'
import { useEffect, useState, createContext, useContext } from 'react'
import type { FunctionComponent } from 'react'
import type { User as FirebaseUser, IdTokenResult, ParsedToken} from 'firebase/auth'
import { onIdTokenChanged } from 'firebase/auth'
import { saveUserInServer, setAuthServerToken } from './authServerActions'
import { auth } from '@/config/firebase'

interface AuthProviderProps {
	serverUser?: ParsedToken | null
	children: React.ReactNode
}


export interface AuthContextValue {
	user: FirebaseUser | null
}

const AuthContext = createContext<AuthContextValue>({
	user: null,
})

export const useAuth = () => useContext(AuthContext)


export const AuthProvider: FunctionComponent<AuthProviderProps> = ({
	children,
}) => {
	const [user, setUser] = useState<AuthContextValue['user']|null>(null)

	const handleIdTokenChanged = async (firebaseUser: FirebaseUser | null) => {
		if (!firebaseUser) {
			setUser(null)
			return
		}

		const idTokenResult : IdTokenResult = await firebaseUser.getIdTokenResult()
		if(idTokenResult.token) {
			setAuthServerToken(idTokenResult.token, idTokenResult.expirationTime)
			saveUserInServer(idTokenResult)
			setUser(firebaseUser)
		}
	}

	const registerChangeListener = async () => {
		return onIdTokenChanged(auth, handleIdTokenChanged)
	}

	useEffect(() => {
		const unsubscribePromise = registerChangeListener()

		return () => {
			unsubscribePromise.then((unsubscribe) => unsubscribe())
		}
	}, [])

	return (
		<AuthContext.Provider
			value={{user: user}}
		>
			{children}
		</AuthContext.Provider>
	)
}
